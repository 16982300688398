import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import Times from "../components/openingtimes"
import Watertemp from "../components/watertemp"
import Link from "gatsby-link"
import Announcements from "../components/announcements"

class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const reviews = data.allGoogleReview.nodes
    const reviewLimit = 130

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Home" />
        <Container>
          <Row className="mb-5">
            <Col sm={12}>
              <h2 className="mb-0 mt-4">
                The beautiful diving venue on the edge of the Lake District.
              </h2>
              <p className="mt-4">
                There are lots of good reasons to visit what many regard as the
                UK’s finest inland diving centre - crystal clear water, great
                underwater attractions, amazing fish life, easy access, great
                facilities and friendly, highly-experienced staff whose sole aim
                is to ensure you have a fantastic day, above and below the
                surface. We think this place is pretty special and we hope you
                will think so too. We look forward to seeing you at Capernwray
                soon.
              </p>
              <Link to="/about/" className="ms-auto">
                About the dive site at Capernwray &#10174;
              </Link>
            </Col>
          </Row>
          <Row className="mb-5">
            <div className="col-md-12">
              <div className="y">
                <h2 className="pt-2">Announcements</h2>
              </div>
              <div>
                <Announcements />
              </div>
            </div>
          </Row>
        </Container>
        <Container sm={12}>
          <Row className="mb-5">
            <Col sm={12}>
              <h2 className="pt-2">Water Temperature and Visibility</h2>
              <Watertemp />
              {/* <iframe
                title='Capernwray Weather'
                className="mt-5 mb-2"
                id="forecast_embed"
                type="text/html"
                frameBorder="0"
                height="245"
                width="100%"
                src="https://forecast.io/embed/#lat=54.140491&lon=-2.724133&name=Capernwray&color=#2f5471&textColor=#2f5471&font=Helvetica&units=uk&htColor=d8503f&ltColor=68b6ee"
              >
                {' '}
              </iframe> */}
            </Col>
          </Row>
        </Container>
        <Container sm={12}>
          <Row>
            <Col sm={12}>
              <h2 className="pt-2">Opening Times</h2>
              <Times season="summer" />
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={12}>
              <div className=" d-flex">
                <h2 className="pt-2">Reviews from Google</h2>
                <a
                  className="ms-auto align-self-center"
                  href="https://www.google.com/search?q=capernwray+diving+centre"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read more reviews &#8599;
                </a>
              </div>
            </Col>
            {reviews.map(review => {
              return (
                <Col md={4} key={review.id}>
                  <div className="bg-white rounded testimonial border">
                    <div className="tour-desc p-4">
                      <div className="fs-2 text-center mb-3">
                        ⭐ ⭐ ⭐ ⭐ ⭐
                      </div>
                      <div className="tour-text text-center">
                        &ldquo;{review.text.substring(0, reviewLimit)}...&rdquo;
                      </div>
                      <div className="d-flex justify-content-center pt-2 pb-2 rounded-circle">
                        <a href={review.sourceLink}>
                          <img
                            className="rounded-circle"
                            src={review.profile_photo_url}
                            alt=""
                          />
                        </a>
                      </div>
                      <div className="link-name d-flex justify-content-center">
                        {review.author_name}
                      </div>
                      <div className="link-position d-flex justify-content-center">
                        {review.relative_time_description}
                      </div>
                    </div>
                  </div>
                </Col>
              )
            })}
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allGoogleReview(filter: { rating: { eq: 5 } }, limit: 3) {
      nodes {
        id
        author_name
        profile_photo_url
        rating
        text
        relative_time_description
        sourceLink
        sourceImage
      }
    }
  }
`
